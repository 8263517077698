import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { QRCortesiaRepository } from 'src/app/core/repositories/qrcortesia/QRCortesiaRepository';
import { environment } from 'src/environments/environment';
import {
    ListarQRCortesiaRequestDTO,
    ListarQRCortesiaResponseDTO,
    GenerarQRCortesiaRequestDTO,
    GenerarQRCortesiaResponseDTO,
    AsignarQRCortesiaRequestDTO,
    AsignarQRCortesiaResponseDTO,
    ReportsRequestDTO,
    DownloadExcelRequestDTO,
    DownloadExcelDTO,
    LeerQrCortesiaRequestDTO,
    LeerQRCortesiaDTO,
    RegistrarQRCortesiaRequestDTO,
    RegistrarQRCortesiaResponseDTO,
    DeleteQRCortesiaResponseDTO,
    DeleteQRCortesiaRequestDTO,
    ListQRInfinityRequestDTO,
    ListQRInfinityResponseDTO,
} from 'src/app/data/dto/QRCortesiaDTO';

@Injectable({
    providedIn: 'root',
})

export class QRCortesiaRestRepository extends QRCortesiaRepository {
    private headers: HttpHeaders;
    constructor(private http: HttpClient) {
        super();
        this.headers = new HttpHeaders({
            'Content-Type': 'application/json',
        });
    }

    listarQRCortesia(request: ListarQRCortesiaRequestDTO): Observable<ListarQRCortesiaResponseDTO> {
        const endpoint = environment.listar_QR_cortesia_endpoint;
        const params = new HttpParams({
            fromObject: {
              evento: request.evento
            },
          });
        const httpOptions = { headers: this.headers, params };
        return this.http
            .get<ListarQRCortesiaResponseDTO>(`${environment.url_base}/${endpoint}`, httpOptions)
            .pipe(
                map((result) => {
                    return result;
                })
            );
    }

    generarQRCortesia(request: GenerarQRCortesiaRequestDTO): Observable<GenerarQRCortesiaResponseDTO> {
        const endpoint = environment.generar_QR_cortesia_endpoint;
        const httpOptions = { headers: this.headers };
        return this.http
            .post<GenerarQRCortesiaResponseDTO>(`${environment.url_base}/${endpoint}`, JSON.stringify(request), httpOptions)
            .pipe(
                map((result) => {
                    return result;
                })
            );
    }

    asignarQRCortesia(request: AsignarQRCortesiaRequestDTO): Observable<AsignarQRCortesiaResponseDTO> {
        const endpoint = environment.asignar_QR_cortesia_endpoint;
        const httpOptions = { headers: this.headers };
        return this.http
            .put<AsignarQRCortesiaResponseDTO>(`${environment.url_base}/${endpoint}`, JSON.stringify(request), httpOptions)
            .pipe(
                map((result) => {
                    return result;
                })
            );
    }

    reporteQRCortesia( request: ReportsRequestDTO ): Observable<ListarQRCortesiaResponseDTO> {
      const endpoint = environment.reports_QR_cortesia_endpoint;
      const params = new HttpParams({
        fromObject: {
          evento: request.evento,
          fechaInicio: request.fechaInicio,
          fechaFin: request.fechaFin,
          admin: request.admin,
        },
      });
      const httpOptions = { headers: this.headers, params };
      return this.http
          .get<ListarQRCortesiaResponseDTO>(
            `${environment.url_base}/${endpoint}`,
            httpOptions
          )
          .pipe(
              map((result) => {
                  return result;
              })
          );
    }

    DownloadExcel(file: DownloadExcelRequestDTO): Observable<DownloadExcelDTO> {
        const endpoint = environment.download_file_excel_QR_cortesia_endpoint;
        const httpOptions = {
          headers: this.headers
        };
        return this.http
          .post<DownloadExcelDTO>(
            `${environment.url_base}/${endpoint}`,
            JSON.stringify(file),
            httpOptions
          )
          .pipe(
            map((result) => {
              return result;
            })
          );
    }

    lecturaQR(id: LeerQrCortesiaRequestDTO): Observable<LeerQRCortesiaDTO> {
        const endpoint = environment.lecturaQRCortesia_endpoint;
        const idQR = id.id;
        const httpOptions = { headers: this.headers };
        return this.http
            .get<LeerQRCortesiaDTO>(`${environment.url_base}/${endpoint}/${idQR}`, httpOptions)
            .pipe(
                map((result) => {
                    return result;
                })
            );
      }

      registrarQR(qr: RegistrarQRCortesiaRequestDTO): Observable<RegistrarQRCortesiaResponseDTO> {
        const endpoint = environment.registrarQRCortesia_endpoint;
        const httpOptions = { headers: this.headers };
        return this.http
          .post<RegistrarQRCortesiaResponseDTO>(`${environment.url_base}/${endpoint}`,
            JSON.stringify(qr),
            httpOptions
          )
            .pipe(
                map((result) => {
                    return result;
                })
            );
      }

      deleteQR(id: DeleteQRCortesiaRequestDTO): Observable<DeleteQRCortesiaResponseDTO> {
        const endpoint = environment.generar_QR_cortesia_endpoint;
        const idDelete = id.idDelete;
        const httpOptions = { headers: this.headers };
        return this.http
            .delete<DeleteQRCortesiaResponseDTO>(`${environment.url_base}/${endpoint}/${idDelete}`, httpOptions)
            .pipe(
                map((result) => {
                    return result;
                })
            );
    }

    reportInfinityQR( request: ListQRInfinityRequestDTO ): Observable<ListQRInfinityResponseDTO> {
      const endpoint = environment.infinity_report_endpoint;
      const params = new HttpParams({
        fromObject: {
          admin: request.admin,
          event: request.event,
        },
      });
      const httpOptions = { headers: this.headers, params };
      return this.http
          .get<ListQRInfinityResponseDTO>(
            `${environment.url_base}/${endpoint}`,
            httpOptions
          )
          .pipe(
              map((result) => {
                  return result;
              })
          );
    }

}
