import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {EventRepository} from 'src/app/core/repositories/event/EventRepository';
import {environment} from 'src/environments/environment';
import {
    EventsRequestDTO,
    AddEventDTO,
    DeleteEventRequestDTO,
    DeleteEventResponseDTO,
    EditEventResponseDTO,
    EventResponseEditDTO,
    EventsAfterAddResponseDTO,
    EventsByIdResponseDTO,
    EventsResponseDTO,
    EventsWithZoneResponseDTO,
    EventZoneDTO,
    MapResponseDTO,
    ZonasRequestDTO,
    ZonasResponseDTO,
    CardRequestDTO,
    CardResponseDTO,
    CardParcheRequestDTO,
    CardParcheResponseDTO,
    CardParcheV2RequestDTO,
    CardParcheV2ResponseDTO,
    QrRequestDTO,
    QrResponseDTO,
    DetalleZonaRequestDTO,
    DetalleZonaDTO,
    ReportsRequestDTO,
    ReportsDTO,
    QRDTO,
    RegistrarQRRequestDTO,
    RegistrarQRResponseDTO,
    ConfirmacionRequestDTO,
    ConfirmacionResponseDTO,
    DownloadExcelRequestDTO,
    DownloadExcelDTO,
    CardParche2V2RequestDTO,
    MPRequestDTO,
    MPResponseDTO,
    ChangeStatusDTO,
    ChangeStatusResponseDTO,
    PendingPaymentMercadoPago,
    ResponsePendingPaymentMercadoPago,
    PaymentUpgrade,
    PaymentUpgradeResponse,
    GeneralReportDTO,
    GeneralReportRequestDTO,
    OnlyEventsDTO,
    OnlyInfinityEventsResponseDTO,
    ReportByMonthRequestDTO,
    ReportByMonthResponseDTO,
    EventByMonthRequestDTO,
    EventByMonthResponseDTO,
} from '../../dto/EventDTO';

@Injectable({providedIn: 'root'})

export class EventRestRepository extends EventRepository {
    private headers: HttpHeaders;
    constructor(private http: HttpClient) {
        super();
        this.headers = new HttpHeaders({'Content-Type': 'application/json'});
    }

    listEvents(request: EventsRequestDTO): Observable < EventsResponseDTO > {
        const endpoint = environment.event_endpoint;
        const params = new HttpParams(
            {
                fromObject: {
                    admin: request.admin,
                }
            }
        );
        const httpOptions = {
            headers: this.headers,
            params
        };
        return this.http.get<EventsResponseDTO>(`${
            environment.url_base
        }/${endpoint}`, httpOptions).pipe(map((result) => {
            return result;
        }));
    }

    addEvent(request: AddEventDTO): Observable < EventsAfterAddResponseDTO > {
        const endpoint = environment.addEvent_endpoint;
        const httpOptions = {
            headers: this.headers
        };
        return this.http.post<EventsAfterAddResponseDTO>(`${
            environment.url_base
        }/${endpoint}`, JSON.stringify(request), httpOptions).pipe(map((result) => {
            return result;
        }));
    }

    deleteEvent(id: DeleteEventRequestDTO): Observable < DeleteEventResponseDTO > {
        const endpoint = environment.event_endpoint;
        const idDelete = id.idDelete;
        const httpOptions = {
            headers: this.headers
        };
        return this.http.delete<DeleteEventResponseDTO>(`${
            environment.url_base
        }/${endpoint}/${idDelete}`, httpOptions).pipe(map((result) => {
            return result;
        }));
    }

    eventWithAreas(request: EventZoneDTO): Observable < EventsWithZoneResponseDTO > {
        const endpoint = environment.event_areas_endpoint;
        const idEvent = request.idZone;
        const httpOptions = {
            headers: this.headers
        };
        return this.http.get<EventsWithZoneResponseDTO>(`${
            environment.url_base
        }/${endpoint}/${idEvent}`, httpOptions).pipe(map((result) => {
            return result;
        }));
    }

    mapOfEvent(id: number): Observable < MapResponseDTO > {
        const endpoint = environment.map_event_endpoint;
        const idEvent = id;
        const httpOptions = {
            headers: this.headers
        };
        return this.http.get<MapResponseDTO>(`${
            environment.url_base
        }/${endpoint}/${idEvent}`, httpOptions).pipe(map((result) => {
            return result;
        }));
    }

    updateEvent(params: EditEventResponseDTO): Observable < EventResponseEditDTO > {
        const endpoint = environment.event_endpoint;
        const idEvent = params.intIDEvento;
        const httpOptions = {
            headers: this.headers
        };
        return this.http.put<EventResponseEditDTO>(`${
            environment.url_base
        }/${endpoint}/${idEvent}`, JSON.stringify(params), httpOptions).pipe(map((result) => {
            return result;
        }));
    }

    getEventById(id: number): Observable < EventsByIdResponseDTO > {
        const endpoint = environment.event_endpoint;
        const idEvent = id;
        const httpOptions = {
            headers: this.headers
        };
        return this.http.get<EventsByIdResponseDTO>(`${
            environment.url_base
        }/${endpoint}/${idEvent}`, httpOptions).pipe(map((result) => {
            return result;
        }));
    }

    getZonasById(id: ZonasRequestDTO): Observable < ZonasResponseDTO > {
        const endpoint = environment.lista_zonas_endpoint;
        const idZona = id;
        const httpOptions = {
            headers: this.headers
        };
        return this.http.get<ZonasResponseDTO>(`${
            environment.url_base
        }/${endpoint}/${idZona}`, httpOptions).pipe(map((result) => {
            return result;
        }));
    }

    payEventById(request: CardRequestDTO): Observable < CardResponseDTO > {
        const endpoint = environment.pago_evento_v2_endpoint;
        const idPago = request.id;
        const httpOptions = {
            headers: this.headers
        };
        return this.http.post<CardResponseDTO>(`${
            environment.url_base
        }/${endpoint}/${idPago}`, JSON.stringify(request), httpOptions).pipe(map((result) => {
            return result;
        }));
    }

    payEventParcheById(request: CardParcheRequestDTO): Observable < CardParcheResponseDTO > {
        const endpoint = environment.pago_evento_parche_endpoint;
        const folio = request.folio;
        const httpOptions = {
            headers: this.headers
        };
        return this.http.post<CardParcheResponseDTO>(`${
            environment.url_base
        }/${endpoint}/${folio}`, JSON.stringify(request), httpOptions).pipe(map((result) => {
            return result;
        }));
    }
    payEventParcheV2ById(request: CardParcheV2RequestDTO): Observable < CardParcheV2ResponseDTO > {
        const endpoint = environment.pago_evento_parche_v2_endpoint;
        const httpOptions = {
            headers: this.headers
        };
        return this.http.post<CardParcheV2ResponseDTO>(`${
            environment.url_base
        }/${endpoint}`, JSON.stringify(request), httpOptions).pipe(map((result) => {
            return result;
        }));
    }

    payEventParche2V2ById(request: CardParche2V2RequestDTO): Observable < CardParcheV2ResponseDTO > {
        const endpoint = environment.pago_evento_parche2_v2_endpoint;
        const httpOptions = {
            headers: this.headers
        };
        return this.http.post<CardParcheV2ResponseDTO>(`${
            environment.url_base
        }/${endpoint}`, JSON.stringify(request), httpOptions).pipe(map((result) => {
            return result;
        }));
    }

    QRById(id: QrRequestDTO): Observable < QrResponseDTO > {
        const endpoint = environment.qr_endpoint;
        const idQR = id.id;
        const httpOptions = {
            headers: this.headers
        };
        return this.http.get<QrResponseDTO>(`${
            environment.url_base
        }/${endpoint}/${idQR}`, httpOptions).pipe(map((result) => {
            return result;
        }));
    }

    zonasById(id: DetalleZonaRequestDTO): Observable < DetalleZonaDTO > {
        const endpoint = environment.zonas_endpoint;
        const idZona = id.id;
        const httpOptions = {
            headers: this.headers
        };
        return this.http.get<DetalleZonaDTO>(`${
            environment.url_base
        }/${endpoint}/${idZona}`, httpOptions).pipe(map((result) => {
            return result;
        }));
    }

    reports(request: ReportsRequestDTO): Observable < ReportsDTO > {
        const endpoint = environment.reports_endpoint;
        const params = new HttpParams(
            {
                fromObject: {
                    evento: request.evento,
                    zona: request.zona,
                    fechaInicio: request.fechaInicio,
                    fechaFin: request.fechaFin,
                    limit: request.limit,
                    offset: request.offset,
                    page: request.page,
                    admin: request.admin,
                    code: request.code
                }
            }
        );
        const httpOptions = {
            headers: this.headers,
            params
        };
        return this.http.get<ReportsDTO>(`${
            environment.url_base
        }/${endpoint}`, httpOptions).pipe(map((result) => {
            return result;
        }));
    }

    lecturaQR(id: QrRequestDTO): Observable < QRDTO > {
        const endpoint = environment.lecturaQR_endpoint;
        const idQR = id.id;
        const httpOptions = {
            headers: this.headers
        };
        return this.http.get<QRDTO>(`${
            environment.url_base
        }/${endpoint}/${idQR}`, httpOptions).pipe(map((result) => {
            return result;
        }));
    }

    registrarQR(qr: RegistrarQRRequestDTO): Observable < RegistrarQRResponseDTO > {
        const endpoint = environment.registrarQR_endpoint;
        const httpOptions = {
            headers: this.headers
        };
        return this.http.post<RegistrarQRResponseDTO>(`${
            environment.url_base
        }/${endpoint}`, JSON.stringify(qr), httpOptions).pipe(map((result) => {
            return result;
        }));
    }

    confirmacionPayment(request: ConfirmacionRequestDTO): Observable < ConfirmacionResponseDTO > {
        const endpoint = environment.confirmacion_evento_endpoint;
        const referencia = request.referencia;
        const httpOptions = {
            headers: this.headers
        };
        return this.http.post<ConfirmacionResponseDTO>(`${
            environment.url_base
        }/${endpoint}/${referencia}`, JSON.stringify(request), httpOptions).pipe(map((result) => {
            return result;
        }));
    }

    DownloadExcel(file: DownloadExcelRequestDTO): Observable < DownloadExcelDTO > {
        const endpoint = environment.download_file_excel_endpoint;
        const httpOptions = {
            headers: this.headers
        };
        return this.http.post<DownloadExcelDTO>(`${
            environment.url_base
        }/${endpoint}`, JSON.stringify(file), httpOptions).pipe(map((result) => {
            return result;
        }));
    }

    payMercadoPago(request: MPRequestDTO): Observable < MPResponseDTO > {
        const endpoint = environment.pago_Mercado_Pago_endpoint;
        const idPago = request.id;
        const httpOptions = {
            headers: this.headers
        };
        return this.http.post<MPResponseDTO>(`${
            environment.url_base
        }/${endpoint}/${idPago}`, JSON.stringify(request), httpOptions).pipe(map((result) => {
            return result;
        }));
    }

    changeStatusEvent(params: ChangeStatusDTO): Observable < ChangeStatusResponseDTO > {
        const endpoint = environment.change_Status_endpoint;
        const id = params.id;
        const httpOptions = {
            headers: this.headers
        };
        return this.http.put<ChangeStatusResponseDTO>(`${
            environment.url_base
        }/${endpoint}/${id}`, JSON.stringify(params), httpOptions).pipe(map((result) => {
            return result;
        }));
    }

    payPendingMercadoPago(request: PendingPaymentMercadoPago): Observable < ResponsePendingPaymentMercadoPago > {
        const endpoint = environment.pendingPayment_Mercado_Pago_endpoint;
        const idPago = request.id;
        const httpOptions = {
            headers: this.headers
        };
        return this.http.post<ResponsePendingPaymentMercadoPago>(`${
            environment.url_base
        }/${endpoint}/${idPago}`, JSON.stringify(request), httpOptions).pipe(map((result) => {
            return result;
        }));
    }

    paymentUpgradeMercadoPago(params: PaymentUpgrade): Observable < PaymentUpgradeResponse > {
        const endpoint = environment.upgrade_payment_endpoint;
        const idEvent = params.id;
        const httpOptions = {
            headers: this.headers
        };
        return this.http.put<PaymentUpgradeResponse>(`${
            environment.url_base
        }/${endpoint}/${idEvent}`, JSON.stringify(params), httpOptions).pipe(map((result) => {
            return result;
        }));
    }

    filterEvent(id: number): Observable < EventsResponseDTO > {
        const endpoint = environment.filter_event_endpoint;
        const idEvent = id;
        const httpOptions = {
            headers: this.headers
        };
        return this.http.get<EventsResponseDTO>(`${
            environment.url_base
        }/${endpoint}/${idEvent}`, httpOptions).pipe(map((result) => {
            return result;
        }));
    }

    generalReport(request: GeneralReportRequestDTO): Observable < GeneralReportDTO > {
        const endpoint = environment.general_report_endpoint;
        const params = new HttpParams(
            {
                fromObject: {
                    event: request.event,
                }
            }
        );
        const httpOptions = {
            headers: this.headers,
            params
        };
        return this.http.get<GeneralReportDTO>(`${
            environment.url_base
        }/${endpoint}`, httpOptions).pipe(map((result) => {
            return result;
        }));
    }

    getOnlyEvents(id: number): Observable < OnlyEventsDTO > {
        const endpoint = environment.only_events_endpoint;
        const idEvent = id;
        const httpOptions = {
            headers: this.headers
        };
        return this.http.get<OnlyEventsDTO>(`${
            environment.url_base
        }/${endpoint}/${idEvent}`, httpOptions).pipe(map((result) => {
            return result;
        }));
    }

    listInfinityEvents(): Observable<OnlyInfinityEventsResponseDTO> {
        const endpoint = environment.infinity_events_endpoint;
        const httpOptions = { headers: this.headers };
        return this.http
            .get<OnlyInfinityEventsResponseDTO>(
                `${environment.url_base}/${endpoint}`,
                httpOptions
            )
            .pipe(
                map((result) => {
                    return result;
                })
            );
    }

    reportByMonth(request: ReportByMonthRequestDTO): Observable < ReportByMonthResponseDTO > {
        const endpoint = environment.report_by_month_endpoint;
        const params = new HttpParams(
            {
                fromObject: {
                    month: request.month,
                    year: request.year,
                }
            }
        );
        const httpOptions = {
            headers: this.headers,
            params
        };
        return this.http.get<ReportByMonthResponseDTO>(`${
            environment.url_base
        }/${endpoint}`, httpOptions).pipe(map((result) => {
            return result;
        }));
    }

    eventsByMonth(request: EventByMonthRequestDTO): Observable < EventByMonthResponseDTO > {
        const endpoint = environment.events_by_month_endpoint;
        const params = new HttpParams(
            {
                fromObject: {
                    admin: request.admin,
                    month: request.month,
                    year: request.year,
                }
            }
        );
        const httpOptions = {
            headers: this.headers,
            params
        };
        return this.http.get<EventByMonthResponseDTO>(`${
            environment.url_base
        }/${endpoint}`, httpOptions).pipe(map((result) => {
            return result;
        }));
    }

}
