import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UsersRepository } from 'src/app/core/repositories/user/UsersRepository';
import { environment } from 'src/environments/environment';
import {
    AddUserDTO,
    ListEventsOfUSerDTO,
    DeleteResponseDTO,
    DeleteUserRequestDTO,
    UpdateResponseDTO,
    UsersRequestDTO,
    UsersResponseDTO,
    UsersResponseResgisterDTO,
    UserUpdateResponseDTO,
    UserIdDTO,
    LogsUserDTO,
    DownloadExcelRequestDTO,
    DownloadExcelDTO,
    OnlyUsersRequestDTO,
    OnlyUsersResponseDTO,
    UserPhoneUpdateDTO,
    UsersResponseUpdatedPhoneDTO,
    UserPhoneVerifyDTO,
    UserEmailUpdateDTO,
    UsersResponseUpdatedEmailDTO,
    UserEmailVerifyDTO,
    UserHistoryLoginResponseDTO,
    UserHistoryLoginDTO
} from '../../dto/UsersDTO';

@Injectable({
    providedIn: 'root',
})

export class UsersRestRepository extends UsersRepository {
    private headers: HttpHeaders;
    constructor(private http: HttpClient) {
        super();
        this.headers = new HttpHeaders({
            'Content-Type': 'application/json',
        });
    }

    getAllUsers(request: UsersRequestDTO): Observable<UsersResponseDTO> {
        const endpoint = environment.users_endpoint;
        const params = new HttpParams(
            {
                fromObject: {
                    admin: request.admin
                }
            }
        );
        const httpOptions = {
            headers: this.headers,
            params
        };
        return this.http
            .get<UsersResponseDTO>(`${environment.url_base}/${endpoint}`, httpOptions)
            .pipe(
                map((result) => {
                    return result;
                })
            );
    }

    getAllAdmins(): Observable<UsersResponseDTO> {
        const endpoint = environment.admins_endpoint;
        const httpOptions = { headers: this.headers };
        return this.http
            .get<UsersResponseDTO>(`${environment.url_base}/${endpoint}`, httpOptions)
            .pipe(
                map((result) => {
                    return result;
                })
            );
    }

    updateUser(request: UserUpdateResponseDTO): Observable<UpdateResponseDTO> {
        const endpoint = environment.users_endpoint;
        const id = request.intIDUsuario;
        const httpOptions = { headers: this.headers };
        return this.http
            .put<UpdateResponseDTO>(`${environment.url_base}/${endpoint}/${id}`, JSON.stringify(request), httpOptions)
            .pipe(
                map((result) => {
                    return result;
                })
            );
    }

    deleteUser(reques: DeleteUserRequestDTO): Observable<DeleteResponseDTO> {
        const endpoint = environment.users_endpoint;
        const id = reques.idDelete;
        const httpOptions = { headers: this.headers };
        return this.http
            .delete<DeleteResponseDTO>(`${environment.url_base}/${endpoint}/${id}`, httpOptions)
            .pipe(
                map((result) => {
                    return result;
                })
            );
    }

    addUser(params: AddUserDTO): Observable<UsersResponseResgisterDTO> {
        const endpoint = environment.register_endpoint;
        const httpOptions = { headers: this.headers };
        return this.http
            .post<UsersResponseResgisterDTO>(`${environment.url_base}/${endpoint}`, JSON.stringify(params), httpOptions)
            .pipe(
                map((result) => {
                    return result;
                })
            );
    }

    changePhone(params: UserPhoneUpdateDTO): Observable<UsersResponseUpdatedPhoneDTO> {
        const endpoint = environment.auth.update_phone;
        const httpOptions = { headers: this.headers };
        return this.http
            .post<UsersResponseUpdatedPhoneDTO>(`${environment.url_base}/${endpoint}`, JSON.stringify(params), httpOptions)
            .pipe(
                map((result) => {
                    return result;
                })
            );
    }

    changeEmail(params: UserEmailUpdateDTO): Observable<UsersResponseUpdatedEmailDTO> {
        const endpoint = environment.auth.update_email;
        const httpOptions = { headers: this.headers };
        return this.http
            .post<UsersResponseUpdatedEmailDTO>(`${environment.url_base}/${endpoint}`, JSON.stringify(params), httpOptions)
            .pipe(
                map((result) => {
                    return result;
                })
            );
    }

    verifyPhone(params: UserPhoneVerifyDTO): Observable<UsersResponseUpdatedPhoneDTO> {
        const endpoint = environment.auth.verify_phone;

        const whatsAppCode = params.whatsAppCode ? params.whatsAppCode : '';

        const httpParams = new HttpParams()
            .set('idUser', params.idUser.toString())
            .set('whatsAppCode', whatsAppCode);

        const httpOptions = {
            headers: this.headers,
            params: httpParams,
        };

        return this.http
            .get<UsersResponseUpdatedPhoneDTO>(
                `${environment.url_base}/${endpoint}`,
                httpOptions
            )
            .pipe(
                map((result) => {
                    return result;
                })
            );
    }

    verifyEmail(params: UserEmailVerifyDTO): Observable<UsersResponseUpdatedEmailDTO> {
        const endpoint = environment.auth.verify_email;

        const emailCode = params.emailCode ? params.emailCode : '';

        const httpParams = new HttpParams()
            .set('idUser', params.idUser.toString())
            .set('emailCode', emailCode);

        const httpOptions = {
            headers: this.headers,
            params: httpParams,
        };

        return this.http
            .get<UsersResponseUpdatedEmailDTO>(
                `${environment.url_base}/${endpoint}`,
                httpOptions
            )
            .pipe(
                map((result) => {
                    return result;
                })
            );
    }

    sendHistoryLogin(params: UserHistoryLoginDTO): Observable<UserHistoryLoginResponseDTO> {
        const endpoint = environment.auth.history_login;
        const httpOptions = { headers: this.headers };
        return this.http
            .post<UserHistoryLoginResponseDTO>(`${environment.url_base}/${endpoint}`, JSON.stringify(params), httpOptions)
            .pipe(
                map((result) => {
                    return result;
                })
            );
    }

    getAllPurchaseTicket(id: UserIdDTO): Observable<ListEventsOfUSerDTO> {
        const endpoint = environment.purchased_tickets_user;
        const httpOptions = { headers: this.headers };
        return this.http
            .get<ListEventsOfUSerDTO>(`${environment.url_base}/${endpoint}/${id}`, httpOptions)
            .pipe(
                map((result) => {
                    return result;
                })
            );
    }

    getUserById(id: number): Observable<UsersResponseDTO> {
        const endpoint = environment.users_endpoint;
        const httpOptions = { headers: this.headers };
        return this.http
            .get<UsersResponseDTO>(`${environment.url_base}/${endpoint}/${id}`, httpOptions)
            .pipe(
                map((result) => {
                    return result;
                })
            );
    }

    getLogsById(id: number): Observable<LogsUserDTO> {
        const endpoint = environment.logs_endpoint;
        const httpOptions = { headers: this.headers };
        return this.http
            .get<LogsUserDTO>(`${environment.url_base}/${endpoint}/${id}`, httpOptions)
            .pipe(
                map((result) => {
                    return result;
                })
            );
    }

    DownloadExcel(file: DownloadExcelRequestDTO): Observable<DownloadExcelDTO> {
        const endpoint = environment.download_file_excel_users_endpoint;
        const httpOptions = {
            headers: this.headers
        };
        return this.http
            .post<DownloadExcelDTO>(
                `${environment.url_base}/${endpoint}`,
                JSON.stringify(file),
                httpOptions
            )
            .pipe(
                map((result) => {
                    return result;
                })
            );
    }

    onlyUsers(request: OnlyUsersRequestDTO): Observable<OnlyUsersResponseDTO> {
        const endpoint = environment.only_user_endpoint;
        const params = new HttpParams(
            {
                fromObject: {
                    admin: request.admin,
                }
            }
        );
        const httpOptions = {
            headers: this.headers,
            params
        };
        return this.http
            .get<OnlyUsersResponseDTO>(`${environment.url_base}/${endpoint}`, httpOptions)
            .pipe(
                map((result) => {
                    return result;
                })
            );
    }

    onlyUsersPrepaid(request: OnlyUsersRequestDTO): Observable<OnlyUsersResponseDTO> {
        const endpoint = environment.only_user_prepaid_endpoint;
        const params = new HttpParams(
            {
                fromObject: {
                    admin: request.admin,
                }
            }
        );
        const httpOptions = {
            headers: this.headers,
            params
        };
        return this.http
            .get<OnlyUsersResponseDTO>(`${environment.url_base}/${endpoint}`, httpOptions)
            .pipe(
                map((result) => {
                    return result;
                })
            );
    }

}
