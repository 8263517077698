import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { QRPrepagoRepository } from 'src/app/core/repositories/qrprepago/QRPrepagoRepository';
import { environment } from 'src/environments/environment';
import {
  GetAllTypeOfPrepaidRequestDTO,
  GetAllTypeOfPrepaidResponseDTO,
  ListarTypesPrepaidRequestDTO,
  ListarTypesPrepaidResponseDTO,
  RequestDTO,
  TypesPrepaidDTO,
  DeleteTypePrepaidRequestDTO,
  UpdateQRPrepaidRequestDTO,
  UpdateQRPrepaidResponseDTO,
  ReportsRequestDTO,
  ReportsTotalRequestDTO,
  ReportsHistoryRequestDTO,
  CardRequestDTO,
  CardResponseDTO,
  CardParcheV2RequestDTO,
  CardParcheV2ResponseDTO,
  CardParche2V2RequestDTO,
  Referenciaprepago,
  RechargeBalanceRequestDTO,
  AddParkingPenDTO,
  ReportsAccountRequestDTO,
  ReportsAccountResponseDTO

} from 'src/app/data/dto/QRPrepaidDTO';

@Injectable({
  providedIn: 'root',
})

export class QRPrepagoRestRepository extends QRPrepagoRepository {
  private headers: HttpHeaders;
  constructor(private http: HttpClient) {
    super();
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
  }

  listarQRPrepago(request: any): Observable<any> {
    const endpoint = environment.listar_QR_prepago_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .post<any>(`${environment.url_base}/${endpoint}`, JSON.stringify(request), httpOptions)
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  hitorialQRPrepago(request: any): Observable<any> {
    const endpoint = environment.hitorial_QR_prepago_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .post<any>(`${environment.url_base}/${endpoint}`, JSON.stringify(request), httpOptions)
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  generarQRPrepago(request: any): Observable<any> {
    const endpoint = environment.add_QR_prepago_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .post<any>(`${environment.url_base}/${endpoint}`, JSON.stringify(request), httpOptions)
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  listTypes(request: ListarTypesPrepaidRequestDTO): Observable<ListarTypesPrepaidResponseDTO> {
    const endpoint = environment.type_prepaid_endpoint;
    const id = request.id;
    const httpOptions = { headers: this.headers };
    return this.http
      .get<ListarTypesPrepaidResponseDTO>(
        `${environment.url_base}/${endpoint}/${id}`,
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  updateTypePrepaid(request: TypesPrepaidDTO): Observable<ListarTypesPrepaidResponseDTO> {
    const endpoint = environment.update_type_prepaid_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .put<ListarTypesPrepaidResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        JSON.stringify(request),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  createTypePrepaid(request: RequestDTO): Observable<TypesPrepaidDTO> {
    const endpoint = environment.create_type_prepaid_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .post<TypesPrepaidDTO>(`${environment.url_base}/${endpoint}`,
        JSON.stringify(request),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  deleteType(request: DeleteTypePrepaidRequestDTO): Observable<ListarTypesPrepaidResponseDTO> {
    const endpoint = environment.delete_type_prepaid_endpoint;
    const id = request.id;
    const httpOptions = {
      headers: this.headers
    };
    return this.http.delete<ListarTypesPrepaidResponseDTO>(`${environment.url_base
      }/${endpoint}/${id}`, httpOptions).pipe(map((result) => {
        return result;
      }));
  }

  getAllTypeOfPrepaid(request: GetAllTypeOfPrepaidRequestDTO): Observable<GetAllTypeOfPrepaidResponseDTO> {
    const endpoint = environment.all_type_prepaid_endpoint;
    const params = new HttpParams({
      fromObject: {
        admin: request.admin,
      }
    });
    const httpOptions = {
      headers: this.headers,
      params
    };
    return this.http
      .get<GetAllTypeOfPrepaidResponseDTO>(`${environment.url_base}/${endpoint}`, httpOptions)
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  updateTypeOfPrepaid(request: UpdateQRPrepaidRequestDTO): Observable<UpdateQRPrepaidResponseDTO> {
    const endpoint = environment.update_prepaid_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .put<UpdateQRPrepaidResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        JSON.stringify(request),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  reports(request: ReportsRequestDTO): Observable<any> {
    const endpoint = environment.reports_QR_prepaid_endpoint;
    const params = new HttpParams(
      {
        fromObject: {
          admin: request.admin,
          tipo: request.tipo,
          email: request.email,
          intIDUsuario: request.intIDUsuario,
          fechaInicio: request.fechaInicio,
          fechaFin: request.fechaFin,
          limit: request.limit,
          offset: request.offset,
          page: request.page,
        }
      }
    );
    const httpOptions = {
      headers: this.headers,
      params
    };
    return this.http.get<any>(`${environment.url_base
      }/${endpoint}`, httpOptions).pipe(map((result) => {
        return result;
      }));
  }

  reportsTotal(request: ReportsTotalRequestDTO): Observable<any> {
    const endpoint = environment.reports_QR_total_prepaid_endpoint;
    const params = new HttpParams(
      {
        fromObject: {
          admin: request.admin,
          fechaInicio: request.fechaInicio,
          fechaFin: request.fechaFin,
          limit: request.limit,
          offset: request.offset,
          page: request.page,
        }
      }
    );
    const httpOptions = {
      headers: this.headers,
      params
    };
    return this.http.get<any>(`${environment.url_base
      }/${endpoint}`, httpOptions).pipe(map((result) => {
        return result;
      }));
  }

  reportsHistory(request: any): Observable<any> {
    const endpoint = environment.reports_QR_history_prepaid_endpoint;
    const params = new HttpParams(
      {
        fromObject: {
          admin: request.admin,
          email: request.email,
          intIDUsuario: request.intIDUsuario,
          tipo: request.tipo,
          limit: request.limit,
          offset: request.offset,
          page: request.page,
        }
      }
    );
    const httpOptions = {
      headers: this.headers,
      params
    };
    return this.http.get<any>(`${environment.url_base
      }/${endpoint}`, httpOptions).pipe(map((result) => {
        return result;
      }));
  }

  payEventById(request: CardRequestDTO): Observable<CardResponseDTO> {
    const endpoint = environment.pago_prepago_v2_endpoint;
    const idPago = request.id;
    const httpOptions = {
      headers: this.headers
    };
    return this.http.post<CardResponseDTO>(`${environment.url_base
      }/${endpoint}/${idPago}`, JSON.stringify(request), httpOptions).pipe(map((result) => {
        return result;
      }));
  }

  payEventParcheV2ById(request: CardParcheV2RequestDTO): Observable<CardParcheV2ResponseDTO> {
    const endpoint = environment.pago_prepago_parche_v2_endpoint;
    const httpOptions = {
      headers: this.headers
    };
    return this.http.post<CardParcheV2ResponseDTO>(`${environment.url_base
      }/${endpoint}`, JSON.stringify(request), httpOptions).pipe(map((result) => {
        return result;
      }));
  }

  payEventParche2V2ById(request: CardParche2V2RequestDTO): Observable<CardParcheV2ResponseDTO> {
    const endpoint = environment.pago_prepago_parche2_v2_endpoint;
    const httpOptions = {
      headers: this.headers
    };
    return this.http.post<CardParcheV2ResponseDTO>(`${environment.url_base
      }/${endpoint}`, JSON.stringify(request), httpOptions).pipe(map((result) => {
        return result;
      }));
  }

  generaReferenciaPago(request: Referenciaprepago): Observable<any> {
    const endpoint = environment.referencia_prepago_endpoint;
    const httpOptions = {
      headers: this.headers
    };
    return this.http.post<any>(`${environment.url_base
      }/${endpoint}`, JSON.stringify(request), httpOptions).pipe(map((result) => {
        return result;
      }));
  }

  accountStatus(request: any): Observable<any> {
    const endpoint = environment.reports_QR_account_status_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .post<any>(`${environment.url_base}/${endpoint}`, JSON.stringify(request), httpOptions)
      .pipe(
        map((result) => {
          return result;
        })
      );
  }
  migrateUser(request: { email: string }): Observable<any> {
    const endpoint = environment.migrate_user_endpoint;
    const httpOptions = { headers: this.headers };

    return this.http
      .post<any>(`${environment.url_base}/${endpoint}`, JSON.stringify(request), httpOptions)
      .pipe(
        map((result) => {
          return result;
        })
      );
  }
  rechargeNewBalance(request: RechargeBalanceRequestDTO): Observable<any> {
    const endpoint = environment.recharge_new_balance_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .post<any>(`${environment.url_base}/${endpoint}`, JSON.stringify(request), httpOptions)
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  registerUserByAdminInPen(request: AddParkingPenDTO): Observable<any> {
    const endpoint = environment.add_parking_pen;
    const httpOptions = { headers: this.headers };
    return this.http
      .post<any>(`${environment.url_base}/${endpoint}`, JSON.stringify(request), httpOptions)
      .pipe(
        map((result) => {
          return result;
        })
      );
  }
  getReportStatus(request: ReportsAccountRequestDTO): Observable<ReportsAccountResponseDTO> {
    const endpoint = environment.report_account;
    const params = new HttpParams()
      .set('startDate', request.startDate)
      .set('endDate', request.endDate);
    const httpOptions = { headers: this.headers, params };
    return this.http
      .get<ReportsAccountResponseDTO>(`${environment.url_base}/${endpoint}`, httpOptions)
      .pipe(
        map((result) => {
          return result;
        })
      );
  }
}
