import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { CommonModule } from '@angular/common';
import { SharedModule } from './shared/shared.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { Localization, SpanishLocalization } from './shared/Localization';
import { ErrorInterceptor, JwtInterceptor } from './auth/interceptor';
import { UserRestRepository } from './data/repository/auth/UserRestRepository';
import { UserRepository } from './core/repositories/auth/UserRepository';
import { ParkingAreaRepository } from './core/repositories/parking-area/ParkingAreaRepository';
import { ParkingAreaRestRepository } from './data/repository/parking-area/ParkingAreaRestRepository';
import { EventRepository } from './core/repositories/event/EventRepository';
import { EventRestRepository } from './data/repository/event/EventRestRepository';
import { UsersRepository } from './core/repositories/user/UsersRepository';
import { UsersRestRepository } from './data/repository/users/UsersRestRepository';
import { BuyTicketRepository } from './core/repositories/buyTickets/BuyTicketRepository';
import { BuyTicketsRestRepository } from './data/repository/buyTickets/BuyTicketsRestRepository';
import { EnclosuresRepository } from './core/repositories/enclosures/EnclosuresRepository';
import { EnclosuresRestRepository } from './data/repository/enclosures/EnclosuresRestRepository';
import { QRCortesiaRepository } from './core/repositories/qrcortesia/QRCortesiaRepository';
import { QRCortesiaRestRepository } from './data/repository/qrcortesia/QRCortesiaRestRepository';
import { EventTypeRepository } from './core/repositories/eventType/EventTypeRepository';
import { EventTypeRestRepository } from './data/repository/typeOfEvents/EventTypeRestRepository';
import { GuardsAreaRepository } from './core/repositories/guardsArea/GuardsAreaRepository';
import { GuardsAreaRestRepository } from './data/repository/guardsArea/GuardsAreaRestRepository';
import { DiscountCodeRepository } from './core/repositories/discountCode/DiscountCodeRepository';
import { DiscountCodeRestRepository } from './data/repository/discountCode/DiscountCodeRestRepository';
import { CartRepository } from './core/repositories/cart/CartRepository';
import { CartRestRepository } from './data/repository/cart/CartRestRepository';
import { QRPrepagoRepository } from './core/repositories/qrprepago/QRPrepagoRepository';
import { QRPrepagoRestRepository } from './data/repository/qrprepago/QRPrepagoRestRepository';

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent
  ],
  imports: [
    BrowserModule,
    NgbModule,
    BrowserAnimationsModule,
    CommonModule,
    SharedModule,
    HttpClientModule,
    AppRoutingModule
  ],
  providers: [
    { provide: Localization, useClass: SpanishLocalization },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: UserRepository, useClass: UserRestRepository },
    { provide: ParkingAreaRepository, useClass: ParkingAreaRestRepository },
    { provide: EventRepository, useClass: EventRestRepository },
    { provide: UsersRepository, useClass: UsersRestRepository },
    { provide: BuyTicketRepository, useClass: BuyTicketsRestRepository },
    { provide: EnclosuresRepository, useClass: EnclosuresRestRepository },
    { provide: QRCortesiaRepository, useClass: QRCortesiaRestRepository },
    { provide: EventTypeRepository, useClass: EventTypeRestRepository },
    { provide: GuardsAreaRepository, useClass: GuardsAreaRestRepository },
    { provide: DiscountCodeRepository, useClass: DiscountCodeRestRepository },
    { provide: CartRepository, useClass: CartRestRepository },
    { provide: QRPrepagoRepository, useClass: QRPrepagoRestRepository },

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
